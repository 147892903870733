import { PageProps } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImageFileWithName, PageContextProps, SiteQueryProps } from '../../utils/graphql';
import SvgDownArrow from '../../images/icons/downArrow.inline.svg';
import { GraphqlLanding } from '../../utils/graphqlNotion';
import PresentationVideo from '../landing/PresentationVideo';
import DefaultLayout from '../layouts/default-layout';
import * as Colors from '../Colors';
import NotTranslated from '../NotTranslated';
import useElementOnScreen from '../../utils/useElementOnScreen';

export type DataType = {
  notionLanding?: GraphqlLanding;
  masterImage: GatsbyImageFileWithName;
  ogImage: GatsbyImageFileWithName;
};

type LandingQueryProps = { site: SiteQueryProps } & DataType;
type LandingProps = PageProps<LandingQueryProps, PageContextProps>;

const Landing: React.VoidFunctionComponent<LandingProps> = ({ data, pageContext }) => {
  const { t } = useTranslation('landings');
  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();
  if (!data.notionLanding) {
    return <NotTranslated pageContext={pageContext} />;
  }
  const landing = data.notionLanding.properties.i18n_key;
  const programFile = 'TRAINING_PROGRAM_JOE_JUSTICE.pdf';
  return (
    <DefaultLayout
      headTitle={t(`${landing}.head-title`)}
      headDescription={t(`${landing}.head-description`)}
      headImageSource={getSrc(data.ogImage)}
      lang={pageContext.locale}
      theme={{ class: Colors.WHITE_ON_BLUE, pseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS }}
      preserveNavbarSpace={false}
      showContactForm={true}
    >
      <div className="fixed flex h-screen w-full">
        <header
          className={`relative flex h-screen w-full snap-start flex-wrap justify-center ${Colors.WHITE_ON_BLUE}`}
        >
          <div
            className={`max-w-4xl translate-y-12 self-center px-4 opacity-0 animate-delay-75 sm:px-8 ${
              isInView ? 'animate-slidein' : ''
            }`}
            ref={animatedElement}
          >
            <p className="mt-8 text-center text-2xl font-medium">{t('introduction.subtitle')}</p>
            <h1 className="sm:text-5xl md:text-6xl">
              {t(`${landing}.introduction.title1`)}
              <br />
              {t(`${landing}.introduction.title2`)}
            </h1>
          </div>
          <a
            className="absolute bottom-12 animate-bounce text-center"
            title={t('introduction.discover')}
            href="#landing-presentation"
            rel="nofollow"
          >
            <SvgDownArrow className="mx-auto block" />
            <p className="mt-3 font-medium">{t('introduction.discover')}</p>
          </a>
        </header>
      </div>
      <div className="relative -z-20 flex h-screen w-0 snap-start"></div>
      <div className={`relative z-10 ${Colors.BLACK_ON_YELLOW}`}>
        <section id="landing-presentation">
          <div className={`${Colors.BLACK_ON_WHITE}`}>
            <div className="container flex h-full max-w-224 flex-col justify-center px-4 py-8 sm:px-8">
              <h2 className="m-0 text-left text-xl font-normal">
                {t('presentation.title-section')}
              </h2>
              <h3 className="m-0 text-left text-[2.3rem] font-semibold leading-[1.2] text-reacteev-blue">
                {t(`${landing}.presentation.title`)}
              </h3>
              <div className="mt-4 flex flex-wrap md:flex-nowrap">
                <div className="mr-8 w-full md:w-1/3">
                  <GatsbyImage
                    image={data.masterImage.childImageSharp.gatsbyImageData}
                    className="mb-8 select-none"
                    title={t(`${landing}.presentation.image-text`)}
                    alt={t(`${landing}.presentation.image-text`)}
                    draggable={false}
                  />
                </div>
                <div className="w-full text-base md:w-2/3">
                  <p className="mb-3">{t(`${landing}.presentation.description1`)}</p>
                  <p className="mb-3">{t(`${landing}.presentation.description2`)}</p>
                  <p className="mb-3">{t(`${landing}.presentation.description3`)}</p>
                  <p className="mb-3">{t(`${landing}.presentation.description4`)}</p>
                  <p className="mb-3">{t(`${landing}.presentation.description5`)}</p>
                  <p className="mb-3">{t(`${landing}.presentation.description6`)}</p>
                  <div className="my-8 md:mb-0">
                    <a
                      href={`/academy/master-class/${programFile}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={t('presentation.download-catalog')}
                      className="inline-block w-fit rounded-1 border-1 border-current px-4 py-3 font-semibold hover:bg-white hover:text-black focus:bg-white focus:text-black active:bg-white active:text-black"
                    >
                      <span className="pr-6">{t('presentation.download-catalog')}</span>
                      <svg
                        className="inline-block rotate-90"
                        width="15"
                        height="9"
                        viewBox="0 0 15 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.803597 5.30357L11.4107 5.30357L9.08038 7.63393C8.75895 7.95536 8.75895 8.4375 9.08038 8.75893C9.40181 9.08036 9.88395 9.08036 10.2054 8.75893L13.9018 5.0625C14.2232 4.74107 14.2232 4.25893 13.9018 3.9375L10.2054 0.241071C9.88396 -0.0803579 9.40181 -0.080358 9.08038 0.241071C8.75896 0.5625 8.75896 1.04464 9.08038 1.36607L11.4107 3.69643L0.803597 3.69643C0.401811 3.69643 2.52311e-05 4.01786 2.51889e-05 4.5C2.51468e-05 4.98214 0.401811 5.30357 0.803597 5.30357Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="landing-pictures" className={`w-full text-center ${Colors.WHITE_ON_BLACK}`}>
          <div className="p-8 text-xl font-semibold xs:text-2xl sm:p-8 md:text-3xl lg:text-4xl xl:text-5xl">
            {t('pictures.text')}
          </div>
        </section>
        <PresentationVideo notFullScreen="" />
      </div>
    </DefaultLayout>
  );
};

export default Landing;
