import { graphql } from 'gatsby';
import Landing from '../components/landings/Landing';

export default Landing;

export const query = graphql`
  query LandingPageQuery($slug: String!, $locale: String!, $key: String!) {
    site {
      siteMetadata {
        siteUrl
        author
        social {
          name
          url
        }
      }
    }
    notionLanding(slug: { eq: $slug }, locale: { eq: $locale }) {
      properties {
        i18n_key
      }
    }
    masterImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "landings" }
      name: { eq: $key }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
      }
    }
    ogImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "landings" }
      name: { eq: $key }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
